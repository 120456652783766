import { useState, FC, useMemo } from 'react';

import FilterItemWithCheckbox from 'src/components/Search/Common/Filters/FilterItemWithCheckbox';
import FilterList from 'src/components/Search/Common/Filters/FilterList';
import LinkMore from 'src/components/Search/Common/Filters/LinkMore';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { useFilterGroups } from 'src/components/Search/Common/Filters/hooks/useFilterGroups';
import { toggleValueInArray } from 'src/components/Search/Common/Filters/utils/toggleValueInArray';
import { useSelector } from 'src/hooks/useSelector';
import { EMPTY_CLUSTER } from 'src/models/search/common/clusters';
import { ClusterGroup, ClusterGroupMap, ClusterKey, SearchTypicalClusterMap } from 'src/types/search/common/clusters';
import { SEARCH_VACANCY_CUSTOM_LABELS } from 'src/utils/constants/searchVacancy';

const EMPTY_ARRAY = [] as const;

export interface FilterContentProps {
    name: keyof SearchTypicalClusterMap;
    sortFunc?: (a: ClusterGroup, b: ClusterGroup) => number;
}

const FilterContent: FC<FilterContentProps> = ({ name, sortFunc }) => {
    const applyFilter = useApplyFilter(name);

    const orders = useSelector((state) => state.searchClustersOrder?.[name]) || EMPTY_ARRAY;
    const { groups, selectedValues } = useSelector((state) => state.searchClusters?.[name]) || EMPTY_CLUSTER;
    const selectedStrValues = useMemo(() => selectedValues.map(String), [selectedValues]);

    const [expanded, setExpanded] = useState(false);
    const { items, breakpoint } = useFilterGroups(
        groups as ClusterGroupMap,
        selectedStrValues.length,
        orders,
        expanded,
        sortFunc
    );

    const itemsToRender = useMemo(() => {
        let itemsLocal = items;
        if (name === ClusterKey.Label) {
            itemsLocal = itemsLocal.filter(({ id }) => !SEARCH_VACANCY_CUSTOM_LABELS.includes(id));
        }
        if (expanded) {
            return itemsLocal;
        }
        return itemsLocal.slice(0, breakpoint);
    }, [breakpoint, expanded, items, name]);

    if (!items.length) {
        return null;
    }

    return (
        <>
            <FilterList expanded={expanded} len={items.length}>
                {itemsToRender.map((item) => (
                    <FilterItemWithCheckbox
                        key={item.id}
                        item={item}
                        name={name}
                        onChange={() => applyFilter(toggleValueInArray(item.id, selectedStrValues))}
                        checked={selectedStrValues.includes(item.id)}
                    />
                ))}
            </FilterList>
            {items.length > breakpoint && (
                <LinkMore
                    length={items.length - breakpoint}
                    expanded={expanded}
                    onClick={() => setExpanded(!expanded)}
                />
            )}
        </>
    );
};

export default FilterContent;
