import { ReactNode, FC } from 'react';

import { FormLabel, VSpacing, Link } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import FilterBottomSheet from 'src/components/Search/Vacancy/Filters/FilterBottomSheet';

interface MobileFilterWithActivatorProps {
    title: ReactNode;
    titleModal: ReactNode;
    add: string;
    selected: ReactNode;
    content: ReactNode;
    searchQuery: string;
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    onClose: () => void;
    onChangeSearchQuery: (value: string) => void;
    showBack?: boolean;
    onBack: () => void;
    activatorDataQa?: string;
    additionalOptions?: ReactNode;
    inputDataQa?: string;
}

const MobileFilterWithActivator: FC<MobileFilterWithActivatorProps> = ({
    setShowModal,
    title,
    titleModal,
    add,
    selected,
    content,
    activatorDataQa,
    inputDataQa,
    ...props
}) => {
    return (
        <>
            <FormLabel>{title}</FormLabel>
            <VSpacing default={12} />
            {selected}
            <VSpacing default={12} />
            <Link
                iconLeft={<PlusOutlinedSize16 />}
                Element="button"
                data-qa={activatorDataQa}
                onClick={() => setShowModal(true)}
            >
                {add}
            </Link>
            <VSpacing default={24} />
            <FilterBottomSheet title={titleModal} inputDataQa={inputDataQa} {...props}>
                {content}
            </FilterBottomSheet>
        </>
    );
};

export default MobileFilterWithActivator;
