import { useCallback, useMemo, useState } from 'react';

import debounce from 'bloko/common/debounce';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import TreeCollection from 'bloko/common/tree/treeCollection';

import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { useDebouncedCountsRequestMobile } from 'src/components/Search/Common/Filters/hooks/useSendFilterForm';
import { toggleValueInArray } from 'src/components/Search/Common/Filters/utils/toggleValueInArray';
import ChildrenMobileFilter, {
    MultiAction,
} from 'src/components/Search/Vacancy/Filters/DepricatedCustomTreeSelector/ChildMobileFilter';
import ParentMobileFilter from 'src/components/Search/Vacancy/Filters/DepricatedCustomTreeSelector/ParentMobileFilter';
import SelectedIndustry from 'src/components/Search/Vacancy/Filters/IndustryAndSubIndustry/SelectedIndustry';
import MobileFilterWithActivator from 'src/components/Search/Vacancy/Filters/MobileFilterWithActivator';
import translation from 'src/components/translation';
import { ClusterKey } from 'src/types/search/common/clusters';

const TrlKeys = {
    add: 'novaFilters.industryAndSubIndustry.add',
    searchParent: 'novaFilters.industryAndSubIndustry.search.parent',
    searchChild: 'novaFilters.industryAndSubIndustry.search.child',
};

interface IndustryAndSubIndustryMobileProps {
    initialValues: string[];
    title: string;
    collection: TreeCollection;
}

const IndustryMobile: TranslatedComponent<IndustryAndSubIndustryMobileProps> = ({
    initialValues,
    title,
    trls,
    collection,
}) => {
    // store's data
    const sendCountsRequest = useDebouncedCountsRequestMobile();
    const applyFilter = useApplyFilter(ClusterKey.Industry);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedValues, setSelectedValues] = useState<string[]>(initialValues);

    // search text and input
    const [searchQuery, setQuery] = useState<string>('');
    const [searchText, setSearchText] = useState<string>('');
    const [selectedParent, setSelectedParent] = useState<string>('');

    // update text (debounce for render children)
    const searchIndustryDebounced = useMemo(
        () =>
            debounce((text: string) => {
                setSearchText(text);
            }, 400),
        []
    );

    // update search input
    const onChangeSearchQuery = useCallback(
        (value: string) => {
            searchIndustryDebounced(value);
            setQuery(value);
        },
        [searchIndustryDebounced]
    );

    // click on parent and child
    const onChangeParent = useCallback((id: string) => {
        setSelectedParent(id);
    }, []);

    const onChangeChild = useCallback(
        (id: string) => {
            const [parent] = id.split('.');
            // if we have parent
            // delete parent and add child
            if (selectedValues.includes(parent)) {
                const filteredValues = selectedValues.filter((item) => parent !== item);
                filteredValues.push(id);
                setSelectedValues(filteredValues);
                applyFilter(filteredValues);
                return;
            }

            // or just add/remove id
            const newSelectedValues = toggleValueInArray(id, selectedValues);
            setSelectedValues(newSelectedValues);
            applyFilter(newSelectedValues);
        },
        [applyFilter, selectedValues]
    );

    // all checkbox
    const onMultiChange = useCallback(
        (action: MultiAction, changedValues: string[]) => {
            const newValues =
                action === MultiAction.Add
                    ? [...selectedValues, ...changedValues]
                    : selectedValues.filter((value) => !changedValues.includes(value));
            setSelectedValues(newValues);
            applyFilter(newValues);
        },
        [applyFilter, selectedValues]
    );

    // fast delete industry
    const handleClear = useCallback(
        (id: string) => {
            const newIndustry = selectedValues.filter((value) => {
                const [parentId] = value.split('.');
                return parentId !== id;
            });
            setSelectedValues(newIndustry);
            applyFilter(newIndustry);
        },
        [applyFilter, selectedValues]
    );

    const handleClose = useCallback(() => {
        setShowModal(false);
        setQuery('');
        setSearchText('');
        onChangeParent('');
        sendCountsRequest();
    }, [onChangeParent, sendCountsRequest]);

    const titleModal = trls[selectedParent ? TrlKeys.searchChild : TrlKeys.searchParent];

    // TODO: перевести на TreeSelector в https://jira.hh.ru/browse/PORTFOLIO-30300
    return (
        <MobileFilterWithActivator
            title={title}
            titleModal={titleModal}
            add={trls[TrlKeys.add]}
            showModal={showModal}
            setShowModal={setShowModal}
            selected={
                <SelectedIndustry
                    selectedValues={selectedValues}
                    collection={collection}
                    onClear={handleClear}
                    onClick={(parentId) => {
                        onChangeParent(parentId);
                        setShowModal(true);
                    }}
                />
            }
            onChangeSearchQuery={onChangeSearchQuery}
            searchQuery={searchQuery}
            showBack={!!selectedParent}
            onBack={() => {
                if (selectedParent) {
                    onChangeParent('');
                    return;
                }
                handleClose();
            }}
            onClose={handleClose}
            content={
                <>
                    {!selectedParent && showModal && (
                        <ParentMobileFilter
                            collection={collection}
                            searchText={searchText}
                            selectedValues={selectedValues}
                            onChange={onChangeParent}
                        />
                    )}
                    {selectedParent && (
                        <ChildrenMobileFilter
                            collection={collection}
                            searchText={searchText}
                            parent={selectedParent}
                            selectedValues={selectedValues}
                            onChange={onChangeChild}
                            name={ClusterKey.Industry}
                            onMultiChange={onMultiChange}
                        />
                    )}
                </>
            }
            activatorDataQa="novafilters-mobile-add-industries"
        />
    );
};

export default translation(IndustryMobile);
