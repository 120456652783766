import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { useBreakpoint } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { useSearchSettings } from 'src/hooks/search/useSearchSettings';
import { useSelector } from 'src/hooks/useSelector';
import { updateSearchCluster } from 'src/models/search/common/clusters';
import { SearchType } from 'src/types/search/common';
import { SearchClusterMap, ClusterKey, SelectedValuesTypeByClusterKey } from 'src/types/search/common/clusters';

const lastUpdatedSearchClusterAction = makeSetStoreField('lastUpdatedSearchCluster');

const FILTER_ANALYTICS_LABELS: Partial<Record<ClusterKey, string>> = {
    [ClusterKey.Compensation]: 'SALARY',
    [ClusterKey.Company]: 'EMPLOYER',
};

const getGenericAnalyticsValue = <F extends keyof SearchClusterMap>(
    selectedValues: SelectedValuesTypeByClusterKey<F> | null | undefined
) => {
    if (Array.isArray(selectedValues)) {
        return selectedValues.length ? selectedValues.join(', ') : 'null';
    }
    return selectedValues || 'null';
};

const sendAnalytics = <F extends keyof SearchClusterMap>(
    filter: F,
    oldSelectedValues: SelectedValuesTypeByClusterKey<F> | null | undefined,
    newSelectedValues: SelectedValuesTypeByClusterKey<F>,
    searchType: SearchType,
    defaultHhtmSource: string,
    isMobile: boolean
) => {
    // Возможные значения buttonName:
    //   –  vacancy_search_filter_apply     /search/vacancy
    //   –  resume_search_filter_apply      /search/resume
    //   –  vacancy_search_filter_touch     /search/vacancy & XS | S filters
    //   –  resume_search_filter_touch      /search/resume  & XS | S filters
    const buttonName = `${searchType}_search_filter_${isMobile ? 'touch' : 'apply'}`;

    // Возможные значения hhtmSource:
    //   –  vacancy_search_list             /search/vacancy | /vacancies/
    //   –  resume_search_result            /search/resume
    //   –  vacancy_search_filter           /search/vacancy & XS | S filters
    //   –  resume_search_filter            /search/resume  & XS | S filters
    //   –  resumes_catalog                 /resumes/
    const hhtmSource = isMobile ? `${searchType}_search_filter` : defaultHhtmSource;

    Analytics.sendHHEventButtonClick(buttonName, {
        type: FILTER_ANALYTICS_LABELS[filter] || filter.toUpperCase(),
        oldValue: JSON.stringify(getGenericAnalyticsValue(oldSelectedValues)),
        newValue: JSON.stringify(getGenericAnalyticsValue(newSelectedValues)),
        hhtmSource,
    });
};

type UseUpdateFilterHook = <F extends ClusterKey>(
    filter: F
) => (newSelectedValues: SelectedValuesTypeByClusterKey<F>) => void;

export const useUpdateFilter: UseUpdateFilterHook = (filter) => {
    const dispatch = useDispatch();

    const { searchType } = useSearchSettings();
    const oldSelectedValues = useSelector((state) => state.searchClusters[filter]?.selectedValues);
    const defaultHhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);
    const { isMobile } = useBreakpoint();

    return useCallback(
        (newSelectedValues) => {
            sendAnalytics(filter, oldSelectedValues, newSelectedValues, searchType, defaultHhtmSource, isMobile);

            dispatch([
                updateSearchCluster({ filter, data: newSelectedValues }),
                lastUpdatedSearchClusterAction(filter),
            ]);
        },
        [filter, oldSelectedValues, searchType, defaultHhtmSource, isMobile, dispatch]
    );
};
