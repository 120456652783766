import { useState, Fragment, FC } from 'react';

import { Checkbox } from '@hh.ru/magritte-ui';

import FilterItem from 'src/components/Search/Common/Filters/FilterItem';
import FilterList from 'src/components/Search/Common/Filters/FilterList';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import LinkMore from 'src/components/Search/Common/Filters/LinkMore';
import ListItem from 'src/components/Search/Common/Filters/ListItem';
import { COLLAPSED_ITEMS_LIMIT } from 'src/components/Search/Common/Filters/hooks/useFilterGroups';
import useChangeChildTreeValue from 'src/components/Search/Vacancy/Filters/DepricatedCustomTreeSelector/hooks/useChangeChildTreeValue';
import useGroupValueByParent from 'src/components/Search/Vacancy/Filters/DepricatedCustomTreeSelector/hooks/useGroupValueByParent';
import { ClusterKey, TypeByClusterKey } from 'src/types/search/common/clusters';

interface ChildTreeProps {
    title: string;
    parentField: 'industryId';
    parentFilter: TypeByClusterKey<typeof ClusterKey.Industry>;
    childrenFilter?: TypeByClusterKey<typeof ClusterKey.SubIndustry>;
    setCheckedValuesWithOnChange: (newValues: string[]) => void;
    selectedValues: string[];
    childrenOrder: string[];
}

const ChildTree: FC<ChildTreeProps> = ({
    title,
    setCheckedValuesWithOnChange,
    selectedValues,
    parentFilter,
    childrenFilter,
    parentField,
    childrenOrder = [],
}) => {
    const childrenLength = childrenFilter ? Object.values(childrenFilter.groups).length : 0;
    const breakpoint = childrenLength - COLLAPSED_ITEMS_LIMIT === 1 ? childrenLength : COLLAPSED_ITEMS_LIMIT;
    const [expanded, setExpanded] = useState(false);
    const childrenMap = useGroupValueByParent(childrenFilter?.groups || {}, parentField, childrenOrder);

    const onChangeChildren = useChangeChildTreeValue(selectedValues, setCheckedValuesWithOnChange);
    if (!childrenFilter || childrenLength === 0) {
        return null;
    }
    let commonIndex = 0;

    return (
        <FilterWrapper filterKey={ClusterKey.Industry} title={title}>
            <FilterList expanded={expanded} len={childrenLength}>
                {Object.keys(childrenMap).map((parentId) => {
                    const parent = parentFilter.groups[parentId];
                    if (commonIndex > breakpoint - 1 && !expanded) {
                        return null;
                    }
                    return (
                        <Fragment key={parentId}>
                            {parent && <ListItem header>{parent.title}</ListItem>}
                            {Object.values(childrenMap[parentId]).map(({ id, title, count }) => {
                                if (commonIndex > breakpoint - 1 && !expanded) {
                                    return null;
                                }
                                commonIndex += 1;
                                return (
                                    <FilterItem
                                        key={id}
                                        left={
                                            <Checkbox
                                                value={id}
                                                onChange={({ target }) => {
                                                    onChangeChildren(target.value);
                                                }}
                                                checked={selectedValues.includes(id)}
                                                dataQaCheckbox="serp__novafilter-item-text"
                                            />
                                        }
                                        title={title}
                                        count={count}
                                    />
                                );
                            })}
                        </Fragment>
                    );
                })}
            </FilterList>
            {childrenLength > breakpoint && (
                <LinkMore
                    length={childrenLength - breakpoint}
                    expanded={expanded}
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                />
            )}
        </FilterWrapper>
    );
};

export default ChildTree;
