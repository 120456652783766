import { useMemo, useCallback } from 'react';

import {
    findStaticDataFetcherItemByValue,
    StaticDataFetcherItem,
    useStaticDataProvider,
} from '@hh.ru/magritte-common-data-provider';
import { Select, FormLabel, VSpacing, Link, Button } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { toggleValueInArray } from 'src/components/Search/Common/Filters/utils/toggleValueInArray';
import ChipsList from 'src/components/Search/Vacancy/Filters/FilterChips';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EMPTY_CLUSTER } from 'src/models/search/common/clusters';
import { ClusterKey } from 'src/types/search/common/clusters';

const TrlKeys = {
    add: 'vacancySearch.neighboursArea.add',
    search: 'vacancySearch.neighboursArea.search',
    title: 'vacancySearch.neighboursArea.title',
    save: 'novaModal.save',
};

const NeighboursAreaMobile: TranslatedComponent = ({ trls }) => {
    const applyFilter = useApplyFilter(ClusterKey.Neighbours);

    const { groups, selectedValues } =
        useSelector((state) => state.searchClusters[ClusterKey.Neighbours]) || EMPTY_CLUSTER;

    const selectItems: StaticDataFetcherItem[] = useMemo(
        () =>
            Object.values(groups).map((item) => ({
                text: item.title,
                value: item.id,
            })),
        [groups]
    );

    const selectProvider = useStaticDataProvider(selectItems);

    const selectChips = useMemo(
        () => Object.values(groups).filter(({ id }) => selectedValues.includes(id)),
        [groups, selectedValues]
    );

    const onChangeNeighbours = useCallback(
        (area: string) => applyFilter(toggleValueInArray(Number(area), selectedValues)),
        [applyFilter, selectedValues]
    );

    return (
        <>
            <FormLabel>{trls[TrlKeys.title]}</FormLabel>
            <VSpacing default={12} />
            <ChipsList name={ClusterKey.Neighbours} options={selectChips} onClear={(id) => onChangeNeighbours(id)} />
            <VSpacing default={12} />
            <Select
                searchable
                multiple
                type="checkbox"
                triggerProps={{
                    size: 'medium',
                    label: trls[TrlKeys.title],
                    elevateLabel: true,
                    stretched: true,
                }}
                value={findStaticDataFetcherItemByValue(selectedValues.map(String), selectItems)}
                name={ClusterKey.Neighbours}
                dataProvider={selectProvider}
                renderTrigger={({ onChange, expanded }) => (
                    <Link
                        iconLeft={<PlusOutlinedSize16 />}
                        Element="button"
                        data-qa="novafilters-mobile-add-neighbours-areas"
                        onClick={() => {
                            onChange?.(!expanded);
                        }}
                    >
                        {trls[TrlKeys.add]}
                    </Link>
                )}
                onChange={(newItems) => applyFilter(newItems.map((item) => Number(item.value)))}
                applyChangesButton={
                    <Button mode="primary" style="accent">
                        {trls[TrlKeys.save]}
                    </Button>
                }
            />
            <VSpacing default={24} />
        </>
    );
};

export default translation(NeighboursAreaMobile);
