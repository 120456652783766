import { useMemo } from 'react';

import { ClusterGroup, ClusterGroupMap } from 'src/types/search/common/clusters';

export const COLLAPSED_ITEMS_LIMIT = 5;

type SortFunc = (a: ClusterGroup, b: ClusterGroup) => number;

export const defaultGroupsSort: SortFunc = (a, b) => b.count - a.count;
export const sortByOrders = (items: ClusterGroup[], orders: string[]): void => {
    items.sort((a, b) => {
        const sortA = orders.indexOf(a.id);
        const sortB = orders.indexOf(b.id);
        if (sortA === -1 && sortB === -1) {
            return 0;
        }
        if (sortA === -1 && sortB !== -1) {
            return 1;
        }
        if (sortA !== -1 && sortB === -1) {
            return -1;
        }
        return sortA - sortB;
    });
};

interface FilterGroupsResult<T extends string> {
    items: Array<ClusterGroup<T>>;
    breakpoint: number;
}

export const useFilterGroups = <T extends string>(
    groups: ClusterGroupMap<T>,
    length: number,
    orders: string[],
    expanded = false,
    sortFunc: SortFunc = defaultGroupsSort
): FilterGroupsResult<T> =>
    useMemo(() => {
        const items: ClusterGroup<T>[] = Object.values(groups);
        // sort by count or use other func
        items.sort(sortFunc);
        if (orders && !expanded) {
            sortByOrders(items, orders);
        }
        const breakpoint = Math.max(COLLAPSED_ITEMS_LIMIT, length);
        return {
            items,
            breakpoint: items.length - breakpoint === 1 ? items.length : breakpoint,
        };
    }, [expanded, groups, length, orders, sortFunc]);
