import { FC } from 'react';

import Text, { TextImportance } from 'bloko/blocks/text';
import numberFormatter from 'bloko/common/numberFormatter';

import { NARROW_NON_BREAKING_SPACE, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './styles.less';

interface FilterCountProps {
    count: number;
    isBloko?: boolean;
}

const FilterCount: FC<FilterCountProps> = ({ count, isBloko }) => {
    if (isBloko) {
        return (
            <>
                {NON_BREAKING_SPACE}
                <span className={styles.filterCount}>
                    <Text Element="span" importance={TextImportance.Tertiary}>
                        {numberFormatter.format(`${count}`, { groupSeparator: NARROW_NON_BREAKING_SPACE })}
                    </Text>
                </span>
            </>
        );
    }

    return <>{numberFormatter.format(`${count}`, { groupSeparator: NARROW_NON_BREAKING_SPACE })}</>;
};

export default FilterCount;
