import { useCallback, useEffect, useMemo, useRef } from 'react';

import {
    findStaticDataFetcherItemByValue,
    StaticDataFetcherItem,
    useStaticDataProvider,
} from '@hh.ru/magritte-common-data-provider';
import {
    Button,
    Card,
    Cell,
    CellRightLabel,
    CellText,
    RenderOptionTypeProps,
    Select,
    VSpacing,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { ClusterGroup, ClusterKey } from 'src/types/search/common/clusters';

interface FilterSelectItem extends StaticDataFetcherItem {
    count: number;
}

const TrlKeys = {
    label: 'searchVacancy.select.label',
    apply: 'searchVacancy.select.apply',
    reset: 'searchVacancy.select.reset',
};

interface FilterSelectProps {
    name: typeof ClusterKey.WorkingHours | typeof ClusterKey.WorkScheduleByDays;
    selectedValues: string[];
    items: Array<ClusterGroup>;
    onChange: (values: string[]) => void;
}

const FilterSelect: TranslatedComponent<FilterSelectProps> = ({ name, selectedValues, items, onChange, trls }) => {
    const isResetActiveRef = useRef(!!selectedValues.length);

    useEffect(() => {
        isResetActiveRef.current = !!selectedValues.length;
    }, [selectedValues]);

    const selectItems: FilterSelectItem[] = useMemo(() => {
        if (!items) {
            return [];
        }
        return items.map(({ id, title, disabled, count }) => ({
            value: id,
            text: title,
            disabled,
            count,
        }));
    }, [items]);

    const renderDesktopItem = useCallback(({ input, data }: RenderOptionTypeProps<FilterSelectItem>) => {
        return (
            <Cell
                disabled={data.disabled}
                Element="div"
                align="top"
                left={input}
                right={data.count ? <CellRightLabel hideIcon>{data.count}</CellRightLabel> : undefined}
            >
                <CellText>{data.text}</CellText>
            </Cell>
        );
    }, []);

    const selectDataProvider = useStaticDataProvider(selectItems);

    const selectValueSortByUserSelect = useMemo(() => {
        const items = findStaticDataFetcherItemByValue(selectedValues, selectItems);
        return items.sort((item1, item2) => {
            return selectedValues.indexOf(item1.value) - selectedValues.indexOf(item2.value);
        });
    }, [selectItems, selectedValues]);

    return (
        <Select
            multiple
            type="checkbox"
            value={selectValueSortByUserSelect}
            name={name}
            dataProvider={selectDataProvider}
            onChange={(values: FilterSelectItem[]) => onChange(values.map(({ value }) => value))}
            onSelectOption={(values) => (isResetActiveRef.current = !!values.length)}
            headerTitle={trls[TrlKeys.label]}
            triggerProps={{
                size: 'medium',
                label: trls[TrlKeys.label],
                stretched: true,
            }}
            applyChangesButton={
                <Button mode="primary" style="accent">
                    {trls[TrlKeys.apply]}
                </Button>
            }
            clearButton={
                <Button mode="secondary" style="accent">
                    {trls[TrlKeys.reset]}
                </Button>
            }
            renderDesktopItem={renderDesktopItem}
            maxHeight={330}
            onDropClose={() => {
                isResetActiveRef.current = !!selectedValues.length;
            }}
            renderContentBefore={({ clearSelectedValuesAndClose }) => (
                <>
                    <Card
                        actionCard
                        showBorder
                        stretched
                        hoverStyle="neutral"
                        padding={16}
                        borderRadius={16}
                        onClick={() => {
                            clearSelectedValuesAndClose();
                            isResetActiveRef.current = false;
                        }}
                        disabled={!isResetActiveRef.current}
                    >
                        <Cell align="center" left={<CrossOutlinedSize24 />}>
                            <CellText>{trls[TrlKeys.reset]}</CellText>
                        </Cell>
                    </Card>
                    <VSpacing default={16} />
                </>
            )}
        />
    );
};

export default translation(FilterSelect);
