import { useCallback, useEffect, useMemo, useState } from 'react';

import debounce from 'bloko/common/debounce';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import TreeCollection from 'bloko/common/tree/treeCollection';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';
import { AdditionalDefault } from 'bloko/common/tree/types';

import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { useDebouncedCountsRequestMobile } from 'src/components/Search/Common/Filters/hooks/useSendFilterForm';
import { toggleValueInArray } from 'src/components/Search/Common/Filters/utils/toggleValueInArray';
import ChildrenMobileFilter, {
    MultiAction,
} from 'src/components/Search/Vacancy/Filters/DepricatedCustomTreeSelector/ChildMobileFilter';
import ParentMobileFilter from 'src/components/Search/Vacancy/Filters/DepricatedCustomTreeSelector/ParentMobileFilter';
import MobileFilterWithActivator from 'src/components/Search/Vacancy/Filters/MobileFilterWithActivator';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ClusterKey } from 'src/types/search/common/clusters';

import SelectedRoles from 'src/components/Search/Vacancy/Filters/ProfessionalRole/Mobile/SelectedRoles';

interface ProfessionalRolesMobileProps {
    initialValues: string[];
    title: string;
}

const TrlKeys = {
    add: 'novaFilters.professionalRoles.add',
    searchParent: 'novaFilters.professionalRoles.search.parent',
    searchChild: 'novaFilters.professionalRoles.search.child',
};

const ProfessionalRolesMobile: TranslatedComponent<ProfessionalRolesMobileProps> = ({ initialValues, title, trls }) => {
    const professionalRoleTree = useSelector(({ professionalRoleTree }) => professionalRoleTree);
    const [collection, setCollection] = useState<TreeCollection<AdditionalDefault>>(
        fromTree(professionalRoleTree.items)
    );
    useEffect(() => setCollection(fromTree(professionalRoleTree.items)), [professionalRoleTree]);

    // store's data
    const applyFilter = useApplyFilter(ClusterKey.ProfessionalRole);
    const sendCountsRequest = useDebouncedCountsRequestMobile();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedValues, setSelectedValues] = useState<string[]>(initialValues);

    // search text and input
    const [searchQuery, setQuery] = useState<string>('');
    const [searchText, setSearchText] = useState<string>('');
    const [selectedParent, setSelectedParent] = useState<string>('');

    // fast delete role
    const handleClear = useCallback(() => {
        setSelectedValues([]);
        applyFilter([]);
    }, [applyFilter]);

    // update text (debounce for render children)
    const searchRolesDebounced = useMemo(
        () =>
            debounce((text: string) => {
                setSearchText(text);
            }, 400),
        []
    );

    // update search input
    const onChangeSearchQuery = useCallback(
        (value: string) => {
            searchRolesDebounced(value);
            setQuery(value);
        },
        [searchRolesDebounced]
    );

    // click on parent and child
    const onChangeParent = useCallback((id: string) => {
        setSelectedParent(id);
    }, []);

    // all checkbox
    const onMultiChange = useCallback(
        (action: MultiAction, changedValues: string[]) => {
            const newValues =
                action === MultiAction.Add
                    ? [...selectedValues, ...changedValues]
                    : selectedValues.filter((value) => !changedValues.includes(value));
            setSelectedValues(newValues);
            applyFilter(newValues.map(Number));
        },
        [applyFilter, selectedValues]
    );

    const onChangeChild = useCallback(
        (id: string) => {
            const newSelectedValues = toggleValueInArray(id, selectedValues);
            setSelectedValues(newSelectedValues);
            applyFilter(newSelectedValues.map(Number));
        },
        [applyFilter, selectedValues]
    );

    const handleClose = useCallback(() => {
        setShowModal(false);
        setQuery('');
        setSearchText('');
        onChangeParent('');
        sendCountsRequest();
    }, [onChangeParent, sendCountsRequest]);

    const titleModal = trls[selectedParent ? TrlKeys.searchChild : TrlKeys.searchParent];

    // TODO: перевести на TreeSelector в https://jira.hh.ru/browse/PORTFOLIO-31402
    return (
        <MobileFilterWithActivator
            title={title}
            titleModal={titleModal}
            add={trls[TrlKeys.add]}
            showModal={showModal}
            setShowModal={setShowModal}
            selected={<SelectedRoles selectedValues={selectedValues} collection={collection} onClear={handleClear} />}
            onChangeSearchQuery={onChangeSearchQuery}
            searchQuery={searchQuery}
            showBack={!!selectedParent}
            onBack={() => {
                if (selectedParent) {
                    onChangeParent('');
                    return;
                }
                handleClose();
            }}
            onClose={handleClose}
            content={
                <>
                    {!selectedParent && showModal && (
                        <ParentMobileFilter
                            collection={collection}
                            searchText={searchText}
                            selectedValues={selectedValues}
                            onChange={onChangeParent}
                        />
                    )}
                    {selectedParent && (
                        <ChildrenMobileFilter
                            collection={collection}
                            searchText={searchText}
                            parent={selectedParent}
                            selectedValues={selectedValues}
                            onChange={onChangeChild}
                            name={ClusterKey.ProfessionalRole}
                            onMultiChange={onMultiChange}
                        />
                    )}
                </>
            }
            activatorDataQa="novafilters-mobile-add-professional-roles"
            inputDataQa="novafilters-mobile-professional-role-input"
        />
    );
};

export default translation(ProfessionalRolesMobile);
