import { Link, VSpacing } from '@hh.ru/magritte-ui';
import { Link as SpaLink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useSetEmployerVacancyListDefault } from 'src/api/employerVacancySearch/useSetEmployerVacancies';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useResetAllLink } from 'src/components/Search/Common/Filters/hooks/useResetAllLink';
import translation from 'src/components/translation';
import { useSearchSettings } from 'src/hooks/search/useSearchSettings';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    reset: 'novafilters.reset',
};

const ResetAll: TranslatedComponent = ({ trls }) => {
    const { isResumeSearchType, isEmployerViewPage } = useSearchSettings();
    const totalUsedVacancyFilters = useSelector((state) => state.vacancySearchResult.totalUsedFilters);
    const totalUsedEmployerVacancySearchFilters = useSelector((state) => state.employerVacancySearch.totalUsedFilters);
    const totalUsedResumeFilters = useSelector((state) => state.resumeSearchResult.totalUsedFilters);
    const totalUsedFilters =
        totalUsedVacancyFilters || totalUsedResumeFilters || totalUsedEmployerVacancySearchFilters || 0;
    const { setEmployerVacancyListDefault } = useSetEmployerVacancyListDefault();
    const resetLink = useResetAllLink();

    const linkProps = {
        style: 'neutral',
        typography: 'label-2-regular',
        disabled: !totalUsedFilters,
        children: trls[TrlKeys.reset],
    } as const;

    return (
        <MobileViewControl>
            {isResumeSearchType && <VSpacing default={24} />}
            {isEmployerViewPage ? (
                <Link {...linkProps} Element="button" onClick={setEmployerVacancyListDefault} />
            ) : (
                <>
                    <Link {...linkProps} Element={SpaLink} to={resetLink} />
                    <VSpacing default={24} />
                </>
            )}
        </MobileViewControl>
    );
};

export default translation(ResetAll);
